.member {
  display: flex;
  flex-direction: column;
  align-items: center;

  .img-container {
    .display-pic {
      img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
        border: 4px solid #539dfe;

        @media only screen and (max-width: 768px) {
          width: 140px;
          height: 140px;
          margin-top: 10px;
        }

        @media only screen and (max-width: 425px) {
          width: 120px;
          height: 120px;
          margin-top: 10px;
        }
      }

      .loader-container {
        width: 100%;
        height: 120px;
        position: relative;
      }
    }
  }

  .name {
    font-size: 1.1rem;
  }

  .position {
    font-size: 1rem;
    color: #539dfe;
  }

  .social-icons {
    display: flex;
    justify-content: center;
    font-size: 1.2rem;
    margin-bottom: 20px;
  }
}

.m-8 {
  margin: 0 8px;
}
