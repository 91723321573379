.section-timeline {
  height: auto !important;
  min-height: 700px;
  color: #111;
  padding: 0 5%;
  background-color: #e9e9e9;

  .section-heading {
    text-align: center;
    font-size: 2.8rem;
    padding-top: 40px;
    margin-top: 0;
  }

  .section-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding-bottom: 50px;

    .timeline-container {
      display: flex;
      flex-direction: column;
      position: relative;
      margin: 30px 0;

      &::after {
        background-color: #e17b77;
        content: "";
        position: absolute;
        left: calc(50% - 2px);
        width: 4px;
        height: 100%;
      }

      .timeline-item {
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding-right: 30px;
        margin: 10px 0;
        width: 50%;

        @media only screen and (min-width: 461px) {
          &:nth-child(odd) {
            align-self: flex-end;
            justify-content: flex-start;
            padding-left: 30px;
            padding-right: 0;
          }
        }

        .timeline-item-content {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          position: relative;
          width: 300px;
          max-width: 300px;
          background-color: #fff;
          border-radius: 5px;
          padding: 15px;
          text-align: right;
          box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);

          &::after {
            content: " ";
            background-color: #fff;
            box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
            position: absolute;
            right: -7.5px;
            top: calc(50% - 7.5px);
            transform: rotate(45deg);
            width: 15px;
            height: 15px;
          }

          img {
            position: absolute;
            top: -15px;
            left: 10px;
            height: 60px;
            border: 1px solid #539dfe;
            border-radius: 5px;
            box-shadow: 10px 10px 16px -9px rgba(0, 0, 0, 0.29);
          }

          time {
            color: #777;
            font-size: 1.2rem;
            font-weight: bold;
            text-align: center;
          }

          ul {
            color: darken(#539dfe, 30%);
            font-weight: 500;
            margin: 0;
            margin-top: 20px;
            padding-inline-start: 22px;
            list-style: none;

            li {
              margin-bottom: 2px;
            }
          }

          .circle {
            background-color: #fff;
            border: 3px solid #e17b77;
            border-radius: 50%;
            position: absolute;
            top: calc(50% - 10px);
            right: -40px;
            width: 20px;
            height: 20px;
            z-index: 100;
          }
        }

        &:nth-child(odd) {
          .timeline-item-content {
            text-align: left;
            align-items: flex-start;

            &::after {
              right: auto;
              left: -7.5px;
              box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
            }

            img {
              left: auto;
              right: 10px;
            }

            ul {
              li::before {
                content: "•";
                color: #539dfe;
                display: inline-block;
                width: 1rem;
                margin-left: -1rem;
              }
            }

            .circle {
              right: auto;
              left: -40px;
            }
          }
        }

        @media only screen and (min-width: 461px) {
          &:nth-child(even) {
            .timeline-item-content {
              ul {
                li::after {
                  content: "•";
                  color: #539dfe;
                  display: inline-block;
                  width: 1rem;
                  margin-right: 0.3rem;
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .section-content {
      padding-bottom: 40px;

      .timeline-item-content {
        max-width: 80% !important;
      }
    }
  }

  @media only screen and (max-width: 460px) {
    .section-content {
      padding-bottom: 30px;

      .timeline-container {
        &::after {
          left: 23px;
        }

        .timeline-item {
          width: 80%;
          margin-left: 55px;
          margin-bottom: 25px;

          .timeline-item-content {
            max-width: 100% !important;

            .circle {
              left: 40px;
              z-index: 100;
            }
          }

          &:nth-child(even) {
            .timeline-item-content {
              text-align: left;
              align-items: flex-start;

              &::after {
                right: auto;
                left: -7.5px;
                box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
              }

              img {
                left: auto;
                right: 10px;
              }

              ul {
                li::before {
                  content: "•";
                  color: #539dfe;
                  display: inline-block;
                  width: 1rem;
                  margin-left: -1rem;
                }
              }

              .circle {
                right: auto;
                left: -40px;
              }
            }
          }
        }
      }
    }
  }

  &.dark {
    background-color: #292929;
    color: #eee;

    .section-content {
      .timeline-container {
        .timeline-item {
          .timeline-item-content {
            background-color: #414141;

            time {
              color: #d1d1d1;
            }

            ul {
              color: #539dfe;
            }

            &::after {
              background-color: #414141;
            }
          }
        }
      }
    }
  }
}
