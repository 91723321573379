.carousel.slider-container {
  text-align: center;
  display: flex !important;
  flex-direction: column;
  align-items: center;

  .slick-slider.center {
    width: 60vw !important;

    .slide {
      width: 68% !important;
      height: 290px;
      display: flex !important;
      justify-content: center;
      align-items: center;
      margin: auto;
      opacity: 0.8;
      transition: all 0.3s ease;
      transform: scale(0.92);
    }

    .image-container {
      width: 90%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
        border: 4px solid #539dfe;
      }
    }

    @media only screen and (max-width: 1024px) {
      width: 80vw !important;

      .slide {
        width: 90% !important;
      }
    }

    @media only screen and (max-width: 768px) {
      width: 80vw !important;

      .slide {
        width: 80% !important;
      }
    }

    @media only screen and (max-width: 425px) {
      width: 80vw !important;

      .slide {
        width: 90% !important;
        height: 200px;
        opacity: 1;
      }
    }

    .slick-center {
      .slide {
        opacity: 1;
        transform: scale(1);
      }
    }

    .slick-next::before,
    .slick-prev::before {
      background-color: #fff;
      color: #000;
    }

    .slick-dots {
      bottom: -35px;
    }

    .slick-dots li button::before {
      font-size: 10px;
    }
  }
}
