.header {
  height: 70px;
  width: 100%;
  position: sticky;
  top: 0;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Poppins", sans-serif;
  padding: 8px 6%;
  z-index: 1000;

  .logo-container {
    height: 100%;
    width: 70px;
    padding-top: 5px;
    text-align: center;
  }

  .logo {
    height: 100%;
    width: 50px;
    cursor: pointer;
  }

  .sidedrawer-btn {
    display: none;
  }

  .options {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: bold;

    .option {
      padding: 10px 15px;
      text-transform: capitalize;
      cursor: pointer;

      &.faq {
        text-transform: uppercase;
      }

      &:hover {
        cursor: pointer;

        &.hover {
          &::before,
          &::after {
            width: 50%;
            opacity: 1;
          }
        }
      }
    }

    .hover {
      position: relative;
      transition: all 0.2s ease-in-out;

      &::before,
      &::after {
        content: "";
        position: absolute;
        bottom: -3px;
        width: 0;
        height: 5px;
        margin: 2px 0 0;
        transition: all 0.2s ease-in-out;
        transition-duration: 0.5s;
        opacity: 0;
        background-color: darken(#539dfe, 5%);
      }

      &::before {
        left: 40%;
      }

      &::after {
        right: 40%;
      }
    }

    .dark-mode-toggle {
      margin-top: 6px;
      padding-top: 1px;
    }
  }

  @media only screen and (max-width: 760px) {
    .options {
      display: none;
    }

    .sidedrawer-btn {
      width: auto;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      font-weight: bold;
      margin-right: 10px;

      .toggle {
        font-size: 1.5rem;
      }

      .dark-mode-toggle {
        margin-top: 8px;
        padding-top: 1px;
        margin-right: 25px;
      }
    }
  }

  &.dark {
    background-color: #2a2a2a;
    color: #eee;

    a {
      color: #eee;
    }
  }
}
