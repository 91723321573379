.section-latestTrends {
  height: 720px !important;
  position: relative;
  text-align: center;

  .section-heading {
    margin-bottom: 10px;
  }

  .sub-heading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -16px 0 15px 0;

    p {
      width: 350px;
      font-size: 1.2rem;
    }
  }

  .section-content {
    display: flex;
    justify-content: center;

    .tech-container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 60px;

      .image-container {
        margin: 0 50px;

        img {
          width: 180px;
          height: 180px;
          border-radius: 50%;
          border: 5px solid #539dfe;
        }

        .loader-container {
          width: 100px;
          height: 100px;
          position: relative;
        }
      }
    }
  }

  .illustration-container {
    .illustration {
      width: 220px;
      position: absolute;
      right: 20px;
      top: 0;
      margin-top: -30px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: auto !important;

    .section-content {
      padding-bottom: 50px;

      .tech-container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 50px;
      }
    }

    .illustration-container {
      .illustration {
        width: 150px;
        margin-top: -20px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .sub-heading {
      p {
        width: 75%;
        font-size: 1rem;
      }
    }

    .section-content {
      .tech-container {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
        justify-items: center;

        .image-container {
          width: 60%;
          margin: 0 20px;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .illustration-container {
      .illustration {
        width: 65px;
        right: 5px;
        margin-top: -10px;
      }
    }
  }
}
