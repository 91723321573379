.section-contact {
  height: 700px !important;
  color: #111;
  padding: 0 5%;
  background-color: #e9e9e9;

  .section-heading {
    text-align: center;
    font-size: 2.8rem;
    padding-top: 40px;
    margin-top: 0;
  }

  .section-content {
    .info {
      width: 650px;
      margin-top: 45px;

      h2 {
        font-size: 1.6rem;
        color: #539dfe;
        font-weight: bold;
      }

      .text {
        font-size: 1.2rem !important;
      }

      .text-2 {
        margin-top: -10px;
      }

      .mail-btn {
        margin-top: 65px;

        a {
          width: 150px;
          font-family: "Poppins", sans-serif;
          font-size: 1.24rem;
          line-height: 1.55;
          padding: 7px 12px;
          border: 2px solid black;
          border-radius: 40px;
          cursor: pointer;

          &:focus {
            outline: none;
          }

          &:hover {
            background-color: black;
            color: white;
          }
        }
      }
    }

    .image-container {
      height: 280px;
      position: relative;

      .illustration {
        width: 800px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }

  @media only screen and (max-width: 768px) {
    height: auto !important;

    .section-content {
      padding-bottom: 10px;

      .info {
        width: 70%;
        margin-top: 0;

        h2 {
          font-size: 1.5rem;
        }

        .text {
          font-size: 1.1rem !important;
        }

        .text-2 {
          margin-top: -15px;
        }

        .mail-btn {
          margin-top: 55px;

          a {
            font-size: 1.1rem;
          }
        }
      }

      .image-container {
        .illustration {
          width: 550px;
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .section-content {
      padding-bottom: 20px;

      .info-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .info {
          width: 60%;
          text-align: center;

          h2 {
            font-size: 1.4rem;
          }
        }
      }

      .image-container {
        .illustration {
          width: 100%;
        }
      }
    }
  }

  &.dark {
    background-color: #292929;
    color: #eee;

    .section-content .info {
      .mail-btn {
        span {
          a {
            color: #eee !important;
            border: 2px solid #eee;

            &:hover {
              background-color: #000;
            }
          }
        }
      }
    }
  }
}
