.section-acmw-accomplishments {
  height: auto !important;
  padding: 30px 5% 100px !important;

  .section-heading {
    text-align: center;
    font-size: 2.8rem;
    padding-top: 40px;
    margin-top: 0;
  }

  .section-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .info {
      width: 95%;
      display: flex;
      justify-content: space-between;
      margin: 60px 0;

      p {
        width: 50%;
        font-size: 1.3rem;
        line-height: 1.75;
      }

      .illustration-container {
        display: flex;
        align-items: center;

        .illustration {
          width: 350px;
        }
      }
    }

    .accomplishments {
      text-align: center;

      .accomplishment {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 50px;

        .title {
          font-size: 1.9rem;
          font-weight: 700;
        }

        .date {
          font-size: 1.45rem;
          font-weight: 500;
          color: #539dfe;
          margin-top: 2px;
        }

        p {
          width: 65%;
          font-size: 1.2rem;
          line-height: 1.8;
          margin-top: 15px;
        }
      }

      .divider-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 50px 0 100px;

        .divider {
          width: 220px;
        }
      }

      .honorary-mentions {
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .arrow-left,
        .arrow-right {
          width: 160px;
        }

        .accomplishment {
          width: 90%;

          p {
            width: 70%;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .section-content {
      padding-bottom: 10px;

      .info {
        width: 90%;
        flex-direction: column;
        align-items: center;
        margin-top: 0;

        p {
          width: 85%;
          text-align: center;
          margin-bottom: 60px;
        }

        .illustration-container {
          .illustration {
            width: 300px;
          }
        }
      }

      .accomplishments {
        .accomplishment {
          margin-top: 50px;

          .title {
            font-size: 1.9rem;
          }

          .date {
            width: 60%;
          }

          p {
            width: 85%;
            text-align: center;
            margin-bottom: 20px;
          }
        }

        .divider-container {
          margin: 50px 0 90px;

          .divider {
            width: 200px;
          }
        }

        .honorary-mentions {
          width: 100%;
          display: flex;
          justify-content: space-evenly;
          align-items: center;

          .arrow-left,
          .arrow-right {
            width: 15%;
          }

          .accomplishment {
            width: 100%;

            .date {
              width: 80%;
              font-size: 1.3rem;
            }

            p {
              width: 85%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 425px) {
    padding: 15px 5% 120px !important;

    .section-heading {
      text-align: center;
      font-size: 2.2rem !important;
      padding-top: 30px !important;
    }

    .section-content {
      .info {
        width: 90%;
        margin: 0 0 30px;

        p {
          width: 100%;
          font-size: 1rem;
          line-height: 1.6;
          margin: 5px 0 60px;
        }

        .illustration-container {
          .illustration {
            width: 220px;
          }
        }
      }

      .accomplishments {
        .accomplishment {
          .title {
            font-size: 1.6rem;
          }

          .date {
            width: 85%;
            font-size: 1.2rem;
          }

          p {
            width: 90%;
            font-size: 1rem;
            line-height: 1.65;
            text-align: justify;
            margin: 15px 0 20px;
          }
        }

        .divider-container {
          margin: 40px 0 70px;

          .divider {
            width: 160px;
          }
        }

        .honorary-mentions {
          width: 100%;
          position: relative;
          padding-top: 20px;

          .arrow-left,
          .arrow-right {
            width: 20%;
            position: absolute;
          }

          .arrow-left {
            top: -30px;

            svg {
              transform: rotateZ(90deg);
            }
          }

          .arrow-right {
            bottom: -90px;

            svg {
              transform: scaleX(-1) rotateZ(90deg);
            }
          }

          .accomplishment {
            width: 100%;

            .date {
              width: 85%;
              font-size: 1.2rem;
            }

            p {
              width: 87%;
            }
          }
        }
      }
    }
  }
}
