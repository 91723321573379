.section-dsp-speakers {
  height: auto;
  background-color: #e9e9e9;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;

  .section-content {
    width: 80vw;

    .speaker-row {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .speaker {
        width: 450px;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 20px 50px;

        .image-container {
          width: 190px;

          img {
            width: 100%;
            border-radius: 50%;
            border: 3px solid #539dfe;
          }

          .loader-container {
            width: 100%;
            height: 150px;
            position: relative;
          }
        }

        h2 {
          font-size: 1.7rem;
          font-weight: 600;
          text-align: center;
          margin-bottom: 0;

          &.title {
            color: #3f92ff;
            font-weight: 500;
          }

          &.name {
            font-size: 1.6rem;
            margin-top: 0;
          }
        }

        p {
          font-size: 1.1rem;
          line-height: 1.55;
          text-align: justify;
        }
      }

      &.odd {
        justify-content: center;

        .speaker {
          width: 50%;
        }
      }
    }
  }

  .illustration-container {
    width: 200px;
    margin-bottom: 5px;
    position: absolute;
    right: 10px;
    top: 10px;
  }

  @media only screen and (max-width: 900px) {
    height: auto !important;

    .section-content {
      width: 88vw;

      .speaker-row {
        width: 100%;

        .speaker {
          width: 100%;

          .image-container {
            width: 190px;
          }

          h2 {
            font-size: 1.5rem;

            &.name {
              font-size: 1.4rem;
            }
          }

          p {
            font-size: 1.1rem;
          }
        }
      }
    }

    .illustration-container {
      width: 150px;
    }
  }

  @media only screen and (max-width: 500px) {
    .section-content {
      width: 90%;

      .speaker-row {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .speaker {
          width: 95%;

          .image-container {
            width: 140px;
          }

          h2 {
            font-size: 1.4rem;

            &.name {
              font-size: 1.3rem;
            }
          }

          p {
            font-size: 1rem;
            text-align: justify;
          }
        }

        &.odd {
          .speaker {
            width: 95%;
          }
        }
      }
    }

    .illustration-container {
      display: none;
    }
  }

  &.dark {
    background-color: #323232;
    color: #eee;
  }
}
