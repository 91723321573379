.section-dsp-sessions {
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5%;
  padding-bottom: 50px;

  .section-content {
    width: 80vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 50px;

    .session {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      margin: 0 0 60px;

      &:nth-child(even) {
        flex-direction: row-reverse;
      }

      .image-container {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 400px;
          height: 320px;
          border: 3px solid #539dfe;
        }

        .loader-container {
          width: 100%;
          height: 200px;
          position: relative;
        }
      }

      .info {
        width: 50%;
        display: flex;
        justify-content: center;

        h2 {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.2;
          text-align: center;

          &.title1 {
            width: 340px;
          }

          &.title2 {
            width: 200px;
          }
        }
      }
    }
  }

  .illustration-container {
    width: 320px;
    margin-bottom: 5px;

    .illustration {
      width: 300px;
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }

  @media only screen and (max-width: 768px) {
    height: auto !important;

    .section-content {
      width: 90vw;

      .session {
        flex-direction: column;

        &:nth-child(even) {
          flex-direction: column;
        }

        .image-container {
          width: 50%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          width: 50%;

          h2 {
            font-size: 1.3rem;

            &.title1 {
              width: 90%;
            }

            &.title2 {
              width: 55%;
            }
          }
        }
      }
    }

    .illustration-container {
      width: auto;
      margin-bottom: 0;

      .illustration {
        width: 180px;
      }
    }
  }

  @media only screen and (max-width: 425px) {
    .section-content {
      width: 100%;
      margin-top: -10px;

      .session {
        margin-bottom: 40px;

        .image-container {
          width: 60%;
        }

        .info {
          h2 {
            font-size: 1.1rem;

            &.title2 {
              width: 70%;
            }
          }
        }
      }
    }

    .illustration-container {
      display: none;
    }
  }

  &.dark {
    background-color: #292929;
    color: #eee;
  }
}
