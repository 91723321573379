.footer {
  background-color: #393939;
  color: #f5f5f5;
  font-family: "Poppins", sans-serif;
  font-size: 1.2rem;
  text-transform: uppercase;
  text-align: center;
  padding: 20px 0;

  a {
    color: #f5f5f5;
    font-weight: 500;

    &:hover {
      color: #86baff;
    }
  }

  .heading {
    font-size: 1.2rem;
    font-weight: 400;
  }

  .sub-heading {
    font-size: 1.2rem;
    margin-top: -10px;

    &.code-of-conduct {
      font-size: 1.3rem;
    }

    &.credit {
      font-size: 0.9rem;
      color: #848484;
      text-transform: capitalize;
      margin: 10px 0 -5px;

      .credit-link {
        color: #a3a3a3;
        cursor: pointer;
      }
    }
  }

  .social-icons {
    display: flex;
    justify-content: center;
    font-size: 2.5rem;
    margin: 30px 0 50px 0;

    .social-link {
      margin: 0 15px;
    }
  }

  .underline {
    font-weight: 400;
    position: relative;

    &::after {
      content: "";
      position: absolute;
      width: 100%;
      top: 95%;
      left: 0;
      border-bottom: 2.5px solid #dd6b71;
    }
  }

  @media only screen and (max-width: 425px) {
    padding: 20px 10px;

    .heading {
      font-size: 1rem;
    }

    .sub-heading {
      font-size: 1rem;
      margin-top: -10px;

      &.code-of-conduct {
        font-size: 1.1rem;
      }

      &.credit {
        font-size: 0.8rem;
      }
    }

    .social-icons {
      font-size: 2rem;
      margin: 20px 0 30px 0;

      .social-link {
        margin: 0 10px;
      }
    }
  }
}
